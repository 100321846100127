import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import React, { useRef } from 'react'
import { ZeppelinImg, frameImg, frameVideoMisli } from '../utils'
import { animateWithGsap } from '../utils/animations'

const HowItWorks = () => {

    const videoRef = useRef()

    useGSAP(() => {

        gsap.from('#chip', {
            scrollTrigger: {
                trigger: '#chip',
                start: '20% bottom',   
            },
            opacity: 0,
            scale: 2,
            duration: 2,
            ease: 'power2.inOut'
        })

        animateWithGsap('.g_fadeIn', {opacity: 1, y: 0, duration: 1, ease: 'power2.inOut'})
    }, [])



  return (
    
    <section className="common-padding">

        <div className="screen-max-width">

            <div id="chip" className="flex-center w-full">
                <img src={ZeppelinImg} alt="chip" width={200} height={180}/>
            </div>

            <div className="flex flex-col items-center">
                <h2 className="hiw-title"> 
                    <br /> Həyəcan, Adrenalin, Əyləncə!
                    <br /> Sən də oyna, sən də uçuuurt!
                </h2>
                <p className="hiw-subtitle">
                Daha rahat oynamaq üçün elə indi Misli.az mobil tətbiqini yüklə və əyləncəyə başla!
                </p>
            </div>

            <div className='flex justify-center items-center gap-10'>
              <a 
                className="px-5 py-2 border-6 rounded-md bg-customOrange text-white font-bold 
                hover:bg-customWhite hover:text-customOrange transition duration-300 ease-in-out "
                href="https://yukle.misli.az/?utm_source=smartbee&utm_medium=landing-page-zeppelin&utm_campaign=zeppelin">
                MOBİL TƏTBİQİ YÜKLƏ
              </a>
            </div>

            <div className="mt-10 md:mt-20 mb-14">
                <div className="relative h-full flex-center">
                    <div className="overflow-hidden">
                        <img src={frameImg} alt="frame" className="bg-transparent relative z-20"/>
                    </div>
                    <div className="hiw-video">
                        <video className="pointer-events-none" playsInline preload="none" muted autoPlay ref={videoRef}>
                            <source src={frameVideoMisli} type="video/mp4"/>
                        </video>
                    </div>
                </div>

                </div>

                <div className="hiw-text-container">
                <div className="flex flex-1 justify-center flex-col">
                  <p className="text-white g_fadeIn">
                  Hər kəsin proqnozuna bax. Son statistikaları izlə. Minimum 0.25 qəpiklə oyna. {' '}
                    <span className="text-white">
                    Avtomərc və avto pul çıxar funksiyaları ilə daha rahat oyna. 
                     <br /> + simvoluna toxun, ikili mərc et. Ürəyincə oyna, istədiyin vaxt pul çıxar!
                    </span>
                  </p>

                 
                </div>


              </div>
            </div>
    </section>

  )
}

export default HowItWorks