import {

    highlightFirstVideoMisli,
    highlightSecondVideoMisli,
    highlightThirdVideoMisli,
    highlightFourthVideoMisli,
  } from "../utils";
  
  export const navLists = ["IDMAN", "VIRTUAL IDMAN", "DIGITAL OYUNLAR", "EPOZ-QAZAN"];

  export const navLinks = {
    "IDMAN": 'https://www.misli.az/idman-novleri',
    "VIRTUAL IDMAN": 'https://www.misli.az/virtual-idman',
    "DIGITAL OYUNLAR": 'https://www.misli.az/epoz-qazan',
    "EPOZ-QAZAN": 'https://www.misli.az/epoz-qazan'

  }
  
  export const hightlightsSlides = [
    {
      id: 1,
      textLists: [
        "",
      ],
      video: highlightFirstVideoMisli,
      videoDuration: 4,
    },
    {
      id: 2,
      textLists: [""],
      video: highlightSecondVideoMisli,
      videoDuration: 4,
    },
    {
      id: 3,
      textLists: [
        "",
      ],
      video: highlightThirdVideoMisli,
      videoDuration: 5,
    },
    {
      id: 4,
      textLists: [""],
      video: highlightFourthVideoMisli,
      videoDuration: 3,
    },
  ];
  
  
  
  export const sizes = [
    { label: '6.1"', value: "small" },
    { label: '6.7"', value: "large" },
  ];
  
  export const footerLinks = [
    "İstifadə Qaydaları",
    "Şəxsi Məlumatların Qorunması",
    "Məsuliyyətli Mərc Etmə",
    "Haqqımızda ",
    "Kampaniyalar",
    "Bizə Yazın ",
    "Bizimlə Əlaqə"
  ];


